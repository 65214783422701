body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif;
}
body,
html {
  height: 100%;
  color: #777;
  line-height: 1.8;
}
#myNavbar {
  color: rgb(42, 48, 102);
}

/* Create a Parallax Effect */
.bgimg-1,
.bgimg-2,
.bgimg-3 {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* First image (Logo. Full height) */
.bgimg-1 {
  background-image: url("./assets/images/parallax1.jpg");
  min-height: 65vh;
}

/* Second image (Portfolio) */
.bgimg-2 {
  background-image: url("./assets/images/parallax2.jpg");
  min-height: 400px;
}

/* Third image (Contact) */
.bgimg-3 {
  background-image: url("./assets/images/parallax3.jpg");
  min-height: 400px;
}

.w3-wide {
  letter-spacing: 10px;
}
.w3-hover-opacity {
  cursor: pointer;
}

/* Turn off parallax scrolling for tablets and phones */
/* @media only screen and (max-device-width: 1600px) {
  .bgimg-1, .bgimg-2, .bgimg-3 {
    /* background-attachment: scroll; */
/* min-height: 600px;
  }
} */

/* @media (max-width: 700px) {
  .bgimg-1 {
    min-height: 80vh;
  }
} */

.row {
  display: flex;
  flex-wrap: wrap;
}

.dropdown {
  position: absolute;
  background-color: white;
  top: 0px;
  left: 0px;
  width: 100%;
}

.on-top {
  z-index: 19 !important;
  position: fixed;
  top: 0;
  right: 0;
}

.links-small-screen {
  width: 100%;
  padding-top: 64px;
  justify-content: space-evenly;
  display: flex;
}
.link-icon {
  width: 150px;
  height: auto;
}

@media (max-width: 700px) {
  .link-icon {
    width: 50px;
  }
}

/* .project-modal {
  z-index: 20;
  padding-top: 100px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
 
} */

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
